import axios from "axios";
import config from "@/configs/index";

const PRE_URL = config.url + "/admin_wallet/api/admin";
const OPEN_URL = config.openHost;
// 获取系统参数配置
export const getSysConfig = (query) => {
  return axios({
    url: PRE_URL + "/sysConfig/list",
    method: "get",
    params: query,
  });
};

// 修改系统参数配置
export const setSysConfig = (params) => {
  return axios({
    url: PRE_URL + "/sysConfig/" + params.id,
    method: "put",
    data: params,
  });
};

// 获取钱包参数配置
export const getWalletConfig = (query) => {
  return axios({
    url: PRE_URL + "/getWalletConfig",
    method: "get",
    params: query,
  });
};

// 修改钱包参数配置
export const setWalletConfig = (data) => {
  return axios({
    url: PRE_URL + "/setWalletConfig",
    method: "post",
    data,
  });
};

// 获取pro启动页配置
export const getStartConfig = (params) => {
  return axios({
    url: OPEN_URL + "/v1/grandet_public/promotion20210610_get_launch_config",
    method: "post",
    data: params,
  });
};

// 修改pro启动页配置
export const setStartConfig = (params) => {
  return axios({
    url: OPEN_URL + "/v1/bsv_admin/promotion20210610_set_launch_config",
    method: "post",
    data: params,
  });
};
