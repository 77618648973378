import axios from "axios";
import config from "@/configs/index";

const PRE_URL = config.openHost;

// 获取APP公告列表
export const fetchAppNoticeList = (params) => {
  return axios({
    url: PRE_URL + "/v1/upgrade_admin/notice/all",
    method: "post",
    data: params,
  });
};

// 添加APP公告
export const postAppNotice = (params) => {
  return axios({
    url: PRE_URL + "/v1/upgrade_admin/notice/add",
    method: "post",
    data: params,
  });
};

// 修改APP公告
export const editAppNotice = (params) => {
  return axios({
    url: PRE_URL + "/v1/upgrade_admin/notice/update",
    method: "post",
    data: params,
  });
};
