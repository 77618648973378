const getters = {
  routes: (state) => state.route.routes,
  roles: (state) => state.route.roles,
  categoryList: (state) => state.news.categoryList,
  newsList: (state) => state.news.newsList,
  adList: (state) => state.ad.adList,
  coinbaseList: (state) => state.coinbase.coinbaseList,
  lastCoinbase: (state) => state.coinbase.lastCoinbase,
  developerList: (state) => state.developer.developerList,
  applist: (state) => state.applist.applist,
  records: (state) => state.withdraw.records,
  userList: (state) => state.stats.userList,
  userStats: (state) => state.stats.userStats,
  tranStats: (state) => state.stats.tranStats,
  openStats: (state) => state.stats.openStats,
  openapiStats: (state) => state.stats.openapiStats,
  promotionStats: (state) => state.stats.promotionStats,
  errCodeList: (state) => state.stats.errCodeList,
  walletConfig: (state) => state.configs.walletConfig,
  sysConfig: (state) => state.configs.sysConfig,
  rankingData: (state) => state.ranking.rankingData,
  adminList: (state) => state.user.adminList,
  adminGroupList: (state) => state.user.adminGroupList,
  publishAreaTree: (state) => state.applist.publishAreaTree,
  bannerList: (state) => state.banner.bannerList,
  startConfig: (state) => state.configs.startConfig,
  appnoticeList: (state) => state.appnotice.appnoticeList,
};
export default getters;
