import { fetchRanking } from "@/api/ranking";

const state = {
  rankingData: {
    total_num: 0,
    manual_num: 0,
    reward: 0,
    rankingList: [],
  },
};

const mutations = {
  SET_RANKING: (state, data) => {
    state.rankingData = {
      totalNum: data.total_num,
      manualNum: data.manual_num,
      reward: data.reward,
      rankingList: data.ranking_list,
    };
  },
};

const actions = {
  getRanking({ commit }, query) {
    fetchRanking(query).then((data) => {
      console.log(data.data.data);
      commit("SET_RANKING", data.data.data);
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
