import {
  editPassWord,
  getAdministrator,
  addAdministrator,
  editAdministrator,
  deleteAdmin,
  getAdminGroup,
  resetPassword,
  addAdministratorGroup,
  editAdminGroup,
  deleteAdminGroup,
} from "@/api/user";
import { Message } from "element-ui";

const state = {
  adminList: {
    items: [],
    total: 0,
  },
  adminGroupList: {
    items: [],
    total: 0,
  },
};

const mutations = {
  SET_ADMINLIST: (state, data) => {
    state.adminList = {
      items: data.data_list,
      total: data.total_num,
    };
  },
  SET_ADMINGROUPLIST: (state, data) => {
    console.log(data);
    state.adminGroupList = {
      items: data.data_list,
      total: data.total_num,
    };
  },
  // DELETE_NEWS: (state, id) => {
  //   state.newsList = {
  //     items: state.newsList.items.filter(news => news.id !== id),
  //     total: state.newsList.total - 1
  //   };
  // }
};

const actions = {
  getAdminList({ commit }, query) {
    getAdministrator(query).then((data) => {
      console.log(data);
      commit("SET_ADMINLIST", data.data.data);
    });
  },
  getAdminGroupList({ commit }, query) {
    getAdminGroup(query).then((data) => {
      commit("SET_ADMINGROUPLIST", data.data.data);
    });
  },
  async deleteAdminGroup({ commit, dispatch, state }, id) {
    try {
      await deleteAdminGroup(id);
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  },
  async createAdminGroup({ dispatch }, data) {
    try {
      await addAdministratorGroup(data);
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  },
  async updateAdminGroup({ commit }, data) {
    try {
      await editAdminGroup(data);
      return true;
    } catch (error) {
      return false;
    }
  },
  // deleteNews({ commit }, id) {
  //   deleteNewsById(id).then(() => {
  //     commit("DELETE_NEWS", id);
  //     Message.success({
  //       message: "删除成功",
  //       duration: 2000
  //     });
  //   });
  // }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
