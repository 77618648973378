const config = {
  url: "https://api.ddpurse.com",
  tierTopUrl: "https://api-btc.tiertop.io",
  origin: "production",
  ucHostLogin: "https://www.ddpurse.com/openapi",
  walletApiHost: "https://www.ddpurse.com",
  openHost: "https://api.ddpurse.com",
  crowdH5: "https://www.ddpurse.com/crowd",
  uc_client_id: "a6e63cb3646287c3e3aa9dd911ab0f08",
  appidMap: {
    BSV: "bsv_coin_regular",
    BTC: "btc_token_regular",
    ETH: "eth_token_reqular",
  },
  nftLiteUrl: "https://foreverrealm.scientifichash.com",
};
export default config;
