import { fetchBannerList } from "@/api/banner";

const state = {
  bannerList: {
    items: [],
    total: 0,
  },
};

const mutations = {
  SET_BANNERLIST: (state, data) => {
    // console.log(data)
    state.bannerList = {
      items: data.list,
      total: data.total,
    };
  },
};

const actions = {
  getBannerList({ commit }, query) {
    fetchBannerList(query).then((data) => {
      // console.log(data.data.data);
      commit("SET_BANNERLIST", data.data.data);
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
