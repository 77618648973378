import {
  getAppList,
  getAppHistoryList,
  updateApplication,
  getApplicationPublishArea,
} from "@/api/developer";
import { Message } from "element-ui";

const state = {
  applist: {
    items: [],
    total: 0,
  },
  publishAreaTree: [],
};

const handleError = () => {
  Message.success({
    message: "操作失败",
    type: "error",
    duration: 2000,
  });
};

const mutations = {
  GET_APP_LIST: (state, data) => {
    state.applist = {
      items: data.data,
      total: data.total,
    };
  },
  GET_APP_HISTORY_LIST: (state, data) => {
    state.applist = {
      items: data.data,
      total: data.total,
    };
  },
  UPDATE_APP: (state, { id, data }) => {
    const list = state.applist.items;
    const newList = list.map((app) => {
      if (+app.id === +id) {
        return {
          ...app,
          ...data,
        };
      }
      return {
        ...app,
      };
    });
    state.applist = {
      items: newList,
      total: state.applist.total,
    };
  },
  GET_PUBLISH_AREA: (state, data) => {
    state.publishAreaTree = data.map((item, index) => {
      return {
        ...item,
        country_id: index,
      };
    });
  },
};

const actions = {
  getAppList({ commit }, query) {
    getAppList(query).then((data) => {
      commit("GET_APP_LIST", data.data);
    });
  },
  getAppHistoryList({ commit }, query) {
    getAppHistoryList(query).then((data) => {
      commit("GET_APP_HISTORY_LIST", data.data);
    });
  },
  update({ commit }, { id, data }) {
    updateApplication(id, data).then(() => {
      commit("UPDATE_APP", {
        id,
        data,
      });
    });
  },
  getPublishArea({ commit }) {
    getApplicationPublishArea().then((data) => {
      commit("GET_PUBLISH_AREA", data.data.data);
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
