import axios from "axios";

// 获取Coinbase列表
export const fetchCoinbaseList = (query) => {
  return axios({
    url: "/api/v1/coinbase",
    method: "get",
    params: query,
  });
};

// https://common.mempool.com/api/v1/coinbase/last
export const getLastCoinbase = () => {
  return axios({
    url: `/api/dotwallet/coinbase`,
    method: "get",
  });
};

export const putCoinbase = (id, data) => {
  return axios({
    url: `/api/v1/coinbase/${id}`,
    method: "put",
    data,
  });
};
