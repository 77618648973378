import { fetchAppNoticeList } from "@/api/appnotice";

const state = {
  appnoticeList: {
    items: [],
    total: 0,
  },
};

const mutations = {
  SET_APPNOTICELIST: (state, data) => {
    console.log(data);
    state.appnoticeList = {
      items: data.data,
      total: data.total,
    };
  },
};

const actions = {
  getAppnoticeList({ commit }, query) {
    fetchAppNoticeList(query).then((data) => {
      console.log(data.data.data);
      commit("SET_APPNOTICELIST", data.data.data);
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
