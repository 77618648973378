import { getWithdrawRecords } from "@/api/withdraw";

const state = {
  records: {
    items: [],
    total: 0,
  },
};

const mutations = {
  GET_RECORDS: (state, data) => {
    state.records = {
      items: data.data.data_list,
      total: data.data.total_num,
    };
  },
};

const actions = {
  getRecords({ commit }, query) {
    getWithdrawRecords(query).then((data) => {
      console.log(data.data);
      commit("GET_RECORDS", data.data);
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
