import {
  fetchUserStats,
  fetchUserList,
  fetchTranStats,
  fetchOpenStats,
  fetchOpenapiStats,
  fetchPromotionStats,
  fetchErrCodeList,
} from "@/api/stats";

const state = {
  userList: {
    items: [],
    total: 0,
  },
  userStats: {
    userCount: 0,
    wxUserCount: 0,
    mobileUserCount: 0,
    emailUserCount: 0,
    fbUserCount: 0,
    googleUserCount: 0,
    monthlyStats: [],
    dailyStats: [],
  },
  openStats: {
    merchantCount: 0,
    appCount: 0,
    orderList: [],
    smallOrderList: [],
  },
  openapiStats: {
    items: [],
    total: 0,
  },
  tranStats: {
    payList: [],
    redbagList: [],
    redbagRecList: [],
    transferList: [],
    walletList: [],
  },
  promotionStats: {
    domesticNum: 0,
    overseaNum: 0,
    domesticList: [],
    overseaList: [],
  },
  errCodeList: {
    items: [],
    total: 0,
  },
};

const mutations = {
  // 用户列表
  SET_USERLIST: (state, data) => {
    state.userList = {
      items: data.data_list,
      total: data.total_num,
    };
  },
  // 用户数据统计
  SET_USERSTATS: (state, data) => {
    state.userStats = {
      userCount: data.userCount,
      wxUserCount: data.wxUserCount,
      mobileUserCount: data.mobileUserCount,
      emailUserCount: data.emailUserCount,
      fbUserCount: data.fbUserCount,
      googleUserCount: data.googleUserCount,
      monthlyStats: data.monthlyStats,
      dailyStats: data.dailyStats,
    };
  },
  // 交易数据统计
  SET_TRANSTATS: (state, data) => {
    state.tranStats = {
      payList: data.payList,
      redbagList: data.redbagList,
      redbagRecList: data.redbagRecList,
      transferList: data.transferList,
      walletList: data.walletList,
    };
  },
  // 开放平台数据统计
  SET_OPENSTATS: (state, data) => {
    state.openStats = {
      merchantCount: data.merchantCount,
      appCount: data.appCount,
      orderList: data.orderList,
      smallOrderList: data.smallOrderList,
    };
  },
  // 开放平台api统计
  SET_OPENAPISTATS: (state, data) => {
    state.openapiStats = {
      items: data.dataList,
      total: data.totalNum,
    };
  },
  // 活动数据统计
  SET_PROMOTIONSTATS: (state, data) => {
    state.promotionStats = {
      domesticNum: data.domesticNum,
      overseaNum: data.overseaNum,
      domesticList: data.domesticList,
      overseaList: data.overseaList,
    };
  },
  // 错误码列表
  SET_ERRCODELIST: (state, data) => {
    state.errCodeList = {
      items: data.data_list,
      total: data.total_num,
    };
  },
};

const actions = {
  // 用户列表
  getUserList({ commit }, query) {
    console.log(query);
    fetchUserList(query).then((data) => {
      console.log(data.data);
      commit("SET_USERLIST", data.data.data);
    });
  },
  // 用户数据统计
  getUserStats({ commit }, query) {
    console.log(query);
    fetchUserStats(query).then((data) => {
      console.log(data.data);
      commit("SET_USERSTATS", data.data.data);
    });
  },
  // 交易数据统计
  getTranStats({ commit }, query) {
    console.log(query);
    fetchTranStats(query).then((data) => {
      console.log(data.data);
      commit("SET_TRANSTATS", data.data.data);
    });
  },
  // 开放平台数据统计
  getOpenStats({ commit }, query) {
    console.log(query);
    fetchOpenStats(query).then((data) => {
      console.log(data.data);
      commit("SET_OPENSTATS", data.data.data);
    });
  },
  // 开放平台api统计
  getOpenapiStats({ commit }, query) {
    console.log(query);
    fetchOpenapiStats(query).then((data) => {
      console.log(data.data);
      commit("SET_OPENAPISTATS", data.data.data);
    });
  },
  // 活动数据统计
  getPromotionStats({ commit }, query) {
    console.log(query);
    fetchPromotionStats(query).then((data) => {
      console.log(data.data);
      commit("SET_PROMOTIONSTATS", data.data.data);
    });
  },
  // 错误码列表
  getErrCodeList({ commit }, query) {
    console.log(query);
    fetchErrCodeList(query).then((data) => {
      console.log(data.data);
      commit("SET_ERRCODELIST", data.data.data);
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
