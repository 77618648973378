import { getSysConfig, getWalletConfig, getStartConfig } from "@/api/configs";

const state = {
  sysConfig: [],
  walletConfig: [],
  startConfig: {},
};

const mutations = {
  // 钱包参数配置
  SET_WALLETCONFIG: (state, data) => {
    console.log(data);
    state.walletConfig = data;
  },
  // 系统参数配置
  SET_SYSCONFIG: (state, data) => {
    console.log(data);
    state.sysConfig = data;
  },
  // pro启动页配置
  SET_STARTCONFIG: (state, data) => {
    console.log(data);
    state.startConfig = data;
  },
};

const actions = {
  // 系统参数配置
  getSysConfig({ commit }, query) {
    getSysConfig(query).then((data) => {
      console.log(data.data.data);
      commit("SET_SYSCONFIG", data.data.data);
    });
  },
  // 钱包参数配置
  getWalletConfig({ commit }, query) {
    getWalletConfig(query).then((data) => {
      console.log(data.data.data);
      commit("SET_WALLETCONFIG", data.data.data);
    });
  },
  // pro启动页配置
  getStartConfig({ commit }, query) {
    getStartConfig(query).then((data) => {
      commit("SET_STARTCONFIG", json);
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
