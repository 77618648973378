import axios from "axios";
import config from "@/configs/index";

const PRE_URL = config.url + "/admin_wallet/api/admin";

// 获取文章列表
export const fetchNewsList = (query) => {
  return axios({
    url: PRE_URL + "/article",
    method: "get",
    params: query,
  });
};

// 新增文章
export const postNews = (data) => {
  return axios({
    url: PRE_URL + "/article",
    method: "post",
    data: data,
  });
};

// 删除文章
export const deleteNews = (id) => {
  return axios({
    url: PRE_URL + "/article/" + id,
    method: "delete",
    // data: {
    //   article_id: id
    // }
  });
};

// 修改文章
export const editNews = (data) => {
  console.log(PRE_URL);
  console.log(data);
  return axios({
    url: PRE_URL + "/article/" + data.article_id,
    method: "put",
    data: data,
  });
};

// 获取分类列表
export const fetchCategoryList = (query) => {
  return axios({
    url: PRE_URL + "/articleType",
    method: "get",
    params: query,
  });
};

// 获取文章详情
export const fetchNewsDetail = (id) => {
  return axios({
    url: PRE_URL + "/article/" + id,
    method: "get",
    // params: {
    //   article_id: id
    // }
  });
};

// 添加文章类别
export const postCategory = (params) => {
  return axios({
    url: PRE_URL + "/articleType",
    method: "post",
    data: params,
  });
};

// 修改文章类别
export const editCategory = (params) => {
  console.log(params);
  return axios({
    url: PRE_URL + "/articleType/" + params.id,
    method: "put",
    data: params,
  });
};

// 删除文章类别置顶
export const deleteCategory = (id) => {
  return axios({
    url: PRE_URL + "/articleType/" + id,
    method: "delete",
  });
};

// 文章类别置顶
export const setTopCategory = (id) => {
  return axios({
    url: PRE_URL + "/articleType/setTop",
    method: "post",
    data: {
      id: id,
    },
  });
};
