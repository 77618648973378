import {
  fetchDevelopers,
  updateDeveloper,
  audit,
  deleteDeveloper,
} from "@/api/developer";
import { Message } from "element-ui";

const state = {
  developerList: {
    items: [],
    total: 0,
  },
};

const handleError = () => {
  Message.success({
    message: "操作失败",
    type: "error",
    duration: 2000,
  });
};

const mutations = {
  GET_DEVELOPER_LIST: (state, data) => {
    state.developerList = {
      items: data.data_list,
      total: +data.total_num,
    };
  },
  UPDATE_DEVELOPER: (state, data) => {
    const updateItem = { ...data };
    try {
      updateItem.merchant_app = JSON.parse(updateItem.merchant_app);
    } catch (error) {
      console.log(error);
    }
    const list = state.developerList.items;
    const newList = list.map((item) => {
      return updateItem.merchant_id === item.merchant_id ? updateItem : item;
    });
    // 更新列表
    state.developerList.items = newList;
  },
  AUDIT: (state, data) => {
    const list = state.developerList.items;
    const newList = list.map((item) => {
      return {
        ...item,
        status:
          data.merchant_id === item.merchant_id ? data.status : item.status,
      };
    });
    // 更新列表
    state.developerList.items = newList;
  },
};

const actions = {
  getDeveloperList({ commit }, query) {
    fetchDevelopers(query).then((data) => {
      console.log(data.data);
      commit("GET_DEVELOPER_LIST", data.data.data);
    });
  },
  updateDeveloper({ commit }, data) {
    updateDeveloper(data)
      .then((res) => {
        if (res.data && res.data.code === 0) {
          commit("UPDATE_DEVELOPER", data);
          Message.success({
            message: "修改成功",
            duration: 2000,
          });
        } else {
          handleError();
        }
      })
      .catch(handleError);
  },
  auditDeveloer({ commit }, data) {
    audit(data)
      .then((res) => {
        if (res.data && res.data.code === 0) {
          commit("AUDIT", data);
          Message.success({
            message: "审核成功",
            duration: 2000,
          });
        } else {
          handleError();
        }
      })
      .catch(handleError);
  },
  deleteDeveloper({ dispatch }, id) {
    deleteDeveloper(id).then((res) => {
      console.log(res);
      if (+res.data.code === 0) {
        Message.success({
          message: "删除成功",
          duration: 2000,
        });
        dispatch("getDeveloperList", {
          page: 1,
          page_size: 1000,
        });
      } else {
        Message.success({
          message: `删除失败：${res.data.msg}`,
          duration: 2000,
        });
      }
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
