import {
  fetchCoinbaseList,
  putCoinbase,
  getLastCoinbase,
} from "@/api/coinbase";
import { Message } from "element-ui";

const state = {
  coinbaseList: {
    items: [],
    total: 0,
  },
  lastCoinbase: {},
};

const mutations = {
  GET_COINBASE_LIST: (state, data) => {
    state.coinbaseList = {
      items: data.data,
      total: data.total,
    };
  },
  SET_COINBASE: (state, data) => {
    const { items, total } = state.coinbaseList;
    const newItems = items.map((coinbase) => {
      if (coinbase.id === data.id) {
        return data;
      } else {
        return coinbase;
      }
    });
    // update
    state.coinbaseList = {
      items: newItems,
      total: total,
    };
  },
  SET_LAST_COINBASE: (state, data) => {
    state.lastCoinbase = data && data.msg;
  },
};

const actions = {
  getCoinbaseList({ commit }, query) {
    fetchCoinbaseList(query).then((data) => {
      commit("GET_COINBASE_LIST", data.data);
    });
  },
  putCoinbase({ commit }, payload) {
    putCoinbase(payload.id, payload.data).then((data) => {
      commit("SET_COINBASE", data.data);
      Message.success({
        message: "操作成功",
        duration: 2000,
      });
    });
  },
  getLastCoinbase({ commit }) {
    getLastCoinbase().then((data) => {
      commit("SET_LAST_COINBASE", data.data);
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
