/**
 * http配置
 */
// 引入axios以及element ui中的loading和message组件
import axios from "axios";
import router from "./router";
import Fingerprint2 from "fingerprintjs2";
import { Loading, Message } from "element-ui";

// 超时时间
axios.defaults.timeout = 0;

// 设备id
let deviceprint = undefined;

const setFingerprint = () => {
  const defaultOptions = {
    preprocessor: null,
    audio: {
      timeout: 1000,
      // On iOS 11, audio context can only be used in response to user interaction.
      // We require users to explicitly enable audio fingerprinting on iOS 11.
      // See https://stackoverflow.com/questions/46363048/onaudioprocess-not-called-on-ios11#46534088
      excludeIOS11: true,
    },
    fonts: {
      swfContainerId: "fingerprintjs2",
      swfPath: "flash/compiled/FontList.swf",
      userDefinedFonts: [],
      extendedJsFonts: true,
    },
    screen: {
      // To ensure consistent fingerprints when users rotate their mobile devices
      detectScreenOrientation: true,
    },
    plugins: {
      sortPluginsFor: [/palemoon/i],
      excludeIE: false,
    },
    extraComponents: [],
    excludes: {
      // Unreliable on Windows, see https://github.com/Valve/fingerprintjs2/issues/375
      enumerateDevices: true,
      // devicePixelRatio depends on browser zoom, and it's impossible to detect browser zoom
      pixelRatio: true,
      // DNT depends on incognito mode for some browsers (Chrome) and it's impossible to detect incognito mode
      doNotTrack: true,
      // uses js fonts already
      fontsFlash: true,

      // #### Browser independent components

      userAgent: true,
      //no (most of the time)
      language: true,
      // no (but not supported by IE)
      hardwareConcurrency: true,

      sessionStorage: true,
      localStorage: true,
      indexedDb: true,
      addBehavior: true,
      openDatabase: true,
      plugins: true,
      adBlock: true,
      fonts: true,
      audio: true,

      // exclude screen
      screenResolution: true,
      availableScreenResolution: true,

      // (most of the time)
      //'canvas': true,
      //'webgl': true,
      //'fonts',
    },
    NOT_AVAILABLE: "not available",
    ERROR: "error",
    EXCLUDED: "excluded",
  };

  Fingerprint2.get(defaultOptions, (components) => {
    var values = components.map((component) => {
      return component.value;
    });
    deviceprint = Fingerprint2.x64hash128(values.join(""), 31);
  });
};

if (window.requestIdleCallback) {
  window.requestIdleCallback(setFingerprint);
} else {
  setTimeout(setFingerprint, 200);
}

// http请求拦截器
var loadinginstace;
axios.interceptors.request.use(
  (config) => {
    // 判断是否存在token，如果存在的话，则每个http header都加上token
    if (localStorage.getItem("token")) {
      config.headers["Authorization"] = `${localStorage.getItem("token")}`;
    }
    // 添加设备id
    if (deviceprint) {
      config.headers["device-id"] = deviceprint;
    }

    // 添加设备id
    config.headers["Accept-Language"] = "zh-CN";
    // element ui Loading方法
    loadinginstace = Loading.service({
      // fullscreen: true,
    });
    return config;
  },
  (error) => {
    loadinginstace.close();
    Message.error({
      message: "加载超时",
    });
    return Promise.reject(error);
  },
);

// http响应拦截器
axios.interceptors.response.use(
  async (data) => {
    // 响应成功关闭loading
    loadinginstace.close();
    switch (data.data.code) {
      case 0:
        return Promise.resolve(data);
      case 75000:
      case 65000:
        // 返回 401 清除token信息并跳转到登录页面
        localStorage.removeItem("token");
        Message.error({
          message: "登陆过期，请重新登陆",
        });
        router.replace({
          path: "/login",
          query: {
            redirect: router.currentRoute.fullPath,
          },
        });
        return Promise.reject(data);
      default:
        Message.error({
          message: data.data.msg,
        });
        return Promise.reject(data);
    }
  },
  (error) => {
    loadinginstace.close();
    if (error.response) {
      switch (error.response.status) {
        case 401:
          // 返回 401 清除token信息并跳转到登录页面
          localStorage.removeItem("token");
          Message.error({
            message: "登陆过期，请重新登陆",
          });
          router.replace({
            path: "/login",
            query: {
              //redirect: router.currentRoute.fullPath
            },
          });
      }
    }
    return Promise.reject(error);
  },
);

export default axios;
