import { fetchAdList } from "@/api/ad";

const state = {
  adList: {
    items: [],
    total: 0,
  },
};

const mutations = {
  SET_ADLIST: (state, data) => {
    state.adList = {
      items: data.data_list,
      total: data.total_num,
    };
  },
};

const actions = {
  getAdList({ commit }, query) {
    fetchAdList(query).then((data) => {
      console.log(data.data.data);
      commit("SET_ADLIST", data.data.data);
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
