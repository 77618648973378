import Vue from "vue";
import VueClipboard from "vue-clipboard2";
import App from "./App.vue";
import router from "./router";
import store from "./store";
// import CKEditor from "@ckeditor/ckeditor5-vue2";

import "./permission.js";
import "./plugins/element.js";
import "./http.js";

Vue.config.productionTip = false;
Vue.use(VueClipboard);
// Vue.use(CKEditor);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
