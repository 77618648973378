import axios from "axios";
import config from "@/configs/index";

const PRE_URL = config.url + "/admin_wallet";
const COMPATE_URL = config.url + "/compatible";

// 获取
export function fetchDevelopers(query) {
  return axios({
    url: COMPATE_URL + "/api/openapi/list_apply",
    method: "get",
    params: query,
  });
}

// 更新
export function updateDeveloper(data) {
  return axios({
    url: PRE_URL + "/api/openapi/edit_apply",
    method: "post",
    data,
  });
}

// 商家审核
export function audit(data) {
  return axios({
    url: COMPATE_URL + "/api/openapi/checkMerchantInfo",
    method: "post",
    data,
  });
}

export function deleteDeveloper(id) {
  return axios({
    url: COMPATE_URL + "/api/openapi/checkMerchantInfo",
    method: "post",
    data: {
      merchant_id: id,
      status: -3,
    },
  });
}

// 获取app列表
export function getAppList(query) {
  return axios({
    url: PRE_URL + "/api/admin/application",
    method: "get",
    params: query,
  });
}

// 获取app历史列表
export function getAppHistoryList(query) {
  return axios({
    url: PRE_URL + "/api/admin/applicationHistory",
    method: "get",
    params: query,
  });
}

export function updateApplication(id, data) {
  return axios({
    url: PRE_URL + "/api/admin/application/" + id,
    method: "put",
    data,
  });
}

export function updateApplicationHistory(id, data) {
  return axios({
    url: PRE_URL + "/api/admin/applicationHistory/" + id,
    method: "put",
    data,
  });
}

// 获取应用上架区域列表
export function getApplicationPublishArea() {
  return axios({
    url: PRE_URL + "/api/admin/area",
    method: "get",
  });
}

// 获取代收款订单流水
export function getOrderList(data) {
  return axios({
    url: config.url + "/ikki/admin_fiat/get_fiat_order_history",
    method: "post",
    data,
  });
}

// 获取代收款金额统计
export function getStatistialList(data) {
  return axios({
    url: config.url + "/ikki/admin_fiat/get_fiat_order_day_report",
    method: "post",
    data,
  });
}

// 开放平台-统计管理-获取订单支付流水
export function getOrderPaymentRecords(data) {
  return axios({
    url: config.url + "/phoenix/admin_statistics_data/get_order_history",
    method: "post",
    data,
  });
}
// 开放平台-统计管理-获取订单支付统计
export function getOrderPaymentStat(data) {
  return axios({
    url: config.url + "/phoenix/admin_statistics_data/get_order_statistics",
    method: "post",
    data,
  });
}

// 开放平台-数据统计管理-UC用户管理
export function getUCUsers(data) {
  return axios({
    url: config.url + "/v1/user/get_user_list",
    method: "post",
    data,
  });
}
// 开放平台-数据统计管理-UC用户管理导出
export function exportUCUsers(data) {
  return axios({
    url: config.url + "/v1/user/export_user_list",
    method: "post",
    data,
  });
}

// 开放平台-分享赚计划-可推广应用
export function getPublishedApp() {
  return axios({
    url: config.url + "/admin_wallet/api/admin/getPublishedApp",
    method: "get"
  });
}

// 开放平台-分享赚计划-创建
export function createPlan(data) {
  return axios({
    url: config.url + "/admin_wallet/api/admin/appDistribution",
    method: "post",
    data,
  });
}

// 开放平台-分享赚计划-编辑
export function editPlan(id,data) {
  return axios({
    url: config.url + "/admin_wallet/api/admin/appDistribution/" + id,
    method: "put",
    data,
  });
}

// 开放平台-分享赚计划-详情
export function getPlanInfo(id) {
  return axios({
    url: config.url + "/admin_wallet/api/admin/appDistribution/" + id,
    method: "get"
  });
}

// 开放平台-分享赚计划-删除
export function deletePlan(id) {
  return axios({
    url: config.url + "/admin_wallet/api/admin/appDistribution/" + id,
    method: "delete"
  });
}

// 开放平台-分享赚计划-设置权重
export function setPlanWeight(data) {
  return axios({
    url: config.url + "/admin_wallet/api/admin/setAppDistributionWeight",
    method: "post",
    data,
  });
}

// 开放平台-分享赚计划-列表
export function getPlanList(query) {
  return axios({
    url: config.url + "/admin_wallet/api/admin/appDistribution",
    method: "get",
    params: query,
  });
}

// 开放平台-分享赚计划-奖励明细
export function getPlanReward(query) {
  return axios({
    url: config.url + "/admin_wallet/api/admin/getAppDistributionReward",
    method: "get",
    params: query,
  });
}

// 开放平台-分享赚计划-导出奖励明细
export function exportPlanReward(data) {
  return axios({
    url: config.url + "/admin_wallet/api/admin/exportAppDistributionReward",
    method: "post",
    data,
  });
}
// 开放平台-分享赚计划-导出推广计划
export function exportPlan(data) {
  return axios({
    url: config.url + "/admin_wallet/api/admin/exportAppDistribution",
    method: "post",
    data,
  });
}

// 开放平台-分享赚计划-客户明细
export function getPlanUser(query) {
  return axios({
    url: config.url + "/admin_wallet/api/admin/getAppDistributionInvite",
    method: "get",
    params: query,
  });
}

// 开放平台-分享赚计划-导出客户明细
export function exportPlanUser(data) {
  return axios({
    url: config.url + "/admin_wallet/api/admin/exportAppDistributionInvite",
    method: "post",
    data,
  });
}