import axios from "axios";
import config from "@/configs/index";

const PRE_URL = config.url + "/admin_wallet/api";

// 获取排行榜数据
export const fetchRanking = (query) => {
  return axios({
    url: PRE_URL + "/admin/activity/getRankingData",
    method: "get",
    params: query,
  });
};

// 增加内部邀请人数
export const postInvite = (params) => {
  return axios({
    url: PRE_URL + "/admin/activity/addInviteNum",
    method: "post",
    data: params,
  });
};

// 审核
export const postAudit = (params) => {
  return axios({
    url: PRE_URL + "/admin/activity/auditRanking",
    method: "post",
    data: params,
  });
};
