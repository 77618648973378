import axios from "axios";
import config from "@/configs/index";

// const PRE_URL = config.url + '/api/promotion/admin';
const PRE_URL2 = config.url + "/admin_wallet/api/admin/activity";

// 提现记录
export const getWithdrawRecords = (query) => {
  return axios({
    url: PRE_URL2 + "/getWithdrawList",
    method: "get",
    params: query,
  });
};

export const auditWithdraw = (data) => {
  return axios({
    url: PRE_URL2 + "/auditWithdraw",
    method: "post",
    data,
  });
};

// 邀请列表
export const getWithdrawRecordInfo = (query) => {
  return axios({
    url: PRE_URL2 + "/getInviteList",
    method: "get",
    params: query,
  });
};

// 修改幸运值
export const editLucky = (params) => {
  return axios({
    url: PRE_URL2 + "/setLucky",
    method: "post",
    data: params,
  });
};
