import router from "./router";
import store from "./store";

const whiteList = ["/login"];

router.beforeEach(async (to, from, next) => {
  // debugger;
  // 前往登录页
  if (to.path === "/login") {
    // debugger;
    // 清空token
    localStorage.setItem("token", "");
    next();
    return;
  }

  const token = localStorage.getItem("token");

  if (token) {
    const rolesMap = store.getters.roles;
    const hasRoles = rolesMap && Object.keys(rolesMap).length > 0;

    if (hasRoles) {
      next();
    } else {
      try {
        const roles = await store.dispatch("route/getRoles");

        // 获取 roles 为空，前往登录页。
        if (Object.keys(roles).length === 0) {
          next("/login");
          return;
        }

        const params = { roles };
        const routes = await store.dispatch("route/generateRoutes", params);
        router.addRoutes(routes);
        next({ ...to, replace: true });
      } catch (error) {
        console.log(error);
        next("/login");
      }
    }
  } else {
    if (whiteList.includes(to.path)) {
      next();
    } else {
      next("/login");
    }
  }
});
