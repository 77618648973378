import { asyncRoutes } from "@/router";
import { getUserInfo } from "@/api/user";

const hasPermission = (roles, route) => {
  if (route && route.code && roles) {
    if (Array.isArray(roles)) {
      return roles.filter((role) => role.code === route.code).length > 0;
    } else {
      return !!roles[route.code];
    }
  } else {
    return true;
  }
};

const filterAsyncRoutes = (asyncRoutes, roles) => {
  const res = [];

  asyncRoutes.forEach((route) => {
    const tmp = { ...route };

    if (hasPermission(roles, tmp)) {
      if (tmp.children) {
        const childRoles = roles[tmp.code] && roles[tmp.code].children;
        tmp.children = filterAsyncRoutes(tmp.children, childRoles);
      }

      res.push(tmp);
    }
  });

  return res;
};

const state = {
  routes: [],
  roles: {},
};

const mutations = {
  SET_ROUTES: (state, routes) => {
    state.routes = routes;
  },
  SET_ROLES: (state, roles) => {
    state.roles = roles;
  },
};

const actions = {
  generateRoutes({ commit }, { roles }) {
    return new Promise((resolve) => {
      // TODO 获取有权限的 routes
      const accessedRoutes = filterAsyncRoutes(asyncRoutes, roles);
      commit("SET_ROUTES", accessedRoutes);
      resolve(accessedRoutes);
    });
  },
  async getRoles({ commit }) {
    try {
      // 获取用户权限列表
      const { data } = await getUserInfo();
      let roles = {};

      if (+data.code === 0) {
        roles = data.data.menus;
      }

      commit("SET_ROLES", roles);
      return roles;
    } catch (error) {
      return {};
    }
  },
  clearRoles({ commit }) {
    commit("SET_ROLES", {});
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
