import Vue from "vue";
import VueRouter from "vue-router";
// import Home from "@/views/Home.vue";
import { nftRouters } from "@/router/nft.js";
import Layout from "@/layout/index.vue";

Vue.use(VueRouter);

const constantRouterMap = [
  {
    path: "/login",
    component: () => import("@/views/login/login.vue"),
    hidden: true,
  },
  {
    path: "/",
    component: Layout,
  },
];

export const asyncRoutes = [
  {
    path: "/walletConfig",
    component: Layout,
    name: "系统设置",
    code: "system",
    meta: {
      title: "系统设置",
      icon: "international",
    },
    children: [
      {
        path: "walletConfig",
        component: () => import("@/views/configs/walletConfig.vue"),
        name: "钱包参数设置",
        code: "wallet_config",
        meta: {
          title: "钱包参数设置",
          icon: "documentation",
          noCache: true,
        },
      },
      {
        path: "sysConfig",
        component: () => import("@/views/configs/sysConfig.vue"),
        name: "系统参数设置",
        code: "system_config",
        meta: {
          title: "系统参数设置",
          icon: "documentation",
          noCache: true,
        },
      },
    ],
  },
  {
    path: "/website",
    component: Layout,
    name: "官网管理",
    code: "website",
    meta: {
      title: "官网管理",
      icon: "international",
      requireAuth: true,
    },
    children: [
      {
        path: "news",
        component: () => import("@/views/official-website/news/index.vue"),
        name: "文章管理",
        code: "article",
        meta: {
          title: "文章管理",
          icon: "documentation",
          noCache: true,
        },
      },
      {
        path: "news/edit/:id(\\d+)",
        component: () => import("@/views/official-website/news/edit.vue"),
        name: "编辑文章",
        code: "article",
        meta: {
          title: "编辑文章",
          noCache: true,
          activeMenu: "/",
        },
        hidden: true,
      },
      {
        path: "news/create",
        component: () => import("@/views/official-website/news/create.vue"),
        name: "添加文章",
        code: "article",
        meta: {
          title: "添加文章",
          noCache: true,
          activeMenu: "/",
        },
        hidden: true,
      },
      {
        path: "catrgory",
        component: () => import("@/views/official-website/category/index.vue"),
        name: "主题管理",
        code: "theme",
        meta: {
          title: "主题管理",
          icon: "tab",
          onCache: true,
        },
      },
      {
        path: "ad",
        component: () => import("@/views/official-website/ad/index.vue"),
        name: "广告管理",
        code: "ad",
        meta: {
          title: "广告管理",
          icon: "tab",
          onCache: true,
        },
      },
    ],
  },
  {
    path: "/app",
    component: Layout,
    name: "博泉应用",
    code: "wallet",
    alwaysShow: true,
    meta: {
      title: "博泉应用",
      icon: "component",
    },
    children: [
      {
        path: "notice",
        component: () => import("@/views/wallet-app/notice/index.vue"),
        name: "web公告管理",
        code: "notice",
        meta: {
          title: "web公告管理",
          icon: "list",
        },
      },
      {
        path: "notice/edit/:id(\\d+)",
        component: () => import("@/views/wallet-app/notice/edit.vue"),
        name: "编辑公告",
        code: "notice",
        meta: {
          title: "编辑公告",
          noCache: true,
          activeMenu: "/app",
        },
        hidden: true,
      },
      {
        path: "notice/create",
        component: () => import("@/views/wallet-app/notice/create.vue"),
        name: "添加公告",
        code: "notice",
        meta: {
          title: "添加公告",
          noCache: true,
          activeMenu: "/app",
        },
        hidden: true,
      },
      {
        path: "audit",
        component: () => import("@/views/wallet-app/developer/index.vue"),
        name: "开发者审核",
        code: "developer",
        meta: {
          title: "开发者审核",
          icon: "skill",
          onCache: true,
        },
      },
      {
        path: "application",
        component: () => import("@/views/wallet-app/appList/index.vue"),
        name: "应用管理",
        code: "application",
        meta: {
          title: "应用管理",
          icon: "skill",
          onCache: true,
        },
      },
      {
        path: "appHistory",
        component: () => import("@/views/wallet-app/appList/history.vue"),
        name: "应用审核",
        code: "app_audit",
        meta: {
          title: "应用审核",
          icon: "skill",
          onCache: true,
        },
      },
      {
        path: "coinbase",
        component: () => import("@/views/wallet-app/coinbase/index.vue"),
        name: "Coinbase",
        code: "coinbase",
        meta: {
          title: "Coinbase",
          icon: "nested",
          onCache: true,
        },
      },
      {
        path: "withdraw",
        component: () => import("@/views/wallet-app/withdraw/index.vue"),
        name: "提现审核",
        code: "withdraw",
        meta: {
          title: "提现审核",
          icon: "nested",
          onCache: true,
        },
      },
      {
        path: "ranking",
        component: () => import("@/views/wallet-app/ranking/index.vue"),
        name: "活动排行",
        code: "ranking",
        meta: {
          title: "活动排行",
          icon: "nested",
          onCache: true,
        },
      },
      {
        path: "blacklist",
        component: () => import("@/views/wallet-app/blacklist/index.vue"),
        name: "活动黑名单",
        code: "blacklist",
        meta: {
          title: "活动黑名单",
          icon: "nested",
          onCache: true,
        },
      },
      {
        path: "badge",
        component: () => import("@/views/wallet-app/badge/index.vue"),
        name: "badge管理",
        code: "badge",
        meta: {
          title: "badge管理",
          icon: "nested",
          onCache: true,
        },
      },
      {
        path: "banner",
        component: () => import("@/views/wallet-app/banner/index.vue"),
        name: "banner管理",
        // code: "banner",
        meta: {
          title: "banner管理",
          icon: "nested",
          onCache: true,
        },
      },
      {
        path: "startpage",
        component: () => import("@/views/wallet-app/startPage/index.vue"),
        name: "pro启动页管理",
        // code: "",
        meta: {
          title: "pro启动页管理",
          icon: "list",
        },
      },
      {
        path: "appnotice",
        component: () => import("@/views/wallet-app/appNotice/index.vue"),
        name: "公告管理",
        // code: "",
        meta: {
          title: "公告管理",
          icon: "list",
        },
      },
      {
        path: "whiteemail",
        component: () => import("@/views/wallet-app/whiteEmail/index.vue"),
        name: "活动邮箱白名单",
        // code: "",
        meta: {
          title: "活动邮箱白名单",
          icon: "list",
        },
      },
      {
        path: "abnormal",
        component: () => import("@/views/wallet-app/abnormalList/index.vue"),
        name: "活动异常名单",
        // code: "",
        meta: {
          title: "活动异常名单",
          icon: "list",
        },
      },
      {
        path: "recommended",
        component: () => import("@/views/wallet-app/application/index.vue"),
        name: "应用推荐",
        // code: "",
        meta: {
          title: "应用推荐",
          icon: "list",
        },
      },
      {
        path: "active",
        component: () => import("@/views/wallet-app/activepage/index.vue"),
        name: "活动页管理",
        // code: "",
        meta: {
          title: "活动页管理",
          icon: "list",
        },
      },
      {
        path: "active/edit/:id(\\d+)",
        component: () => import("@/views/wallet-app/activepage/edit.vue"),
        name: "编辑活动页",
        //  code: "notice",
        meta: {
          title: "编辑活动页",
          noCache: true,
          activeMenu: "/app",
        },
        hidden: true,
      },
      {
        path: "active/create",
        component: () => import("@/views/wallet-app/activepage/create.vue"),
        name: "添加活动页",
        //  code: "notice",
        meta: {
          title: "添加活动页",
          noCache: true,
          activeMenu: "/app",
        },
        hidden: true,
      },
    ],
  },
  //  TODO 隐藏部分
  // {
  //   path: "/stats",
  //   component: Layout,
  //   name: "数据统计管理",
  //   code: "stats",
  //   meta: {
  //     title: "数据统计管理",
  //     icon: "international",
  //   },
  //   children: [
  //     {
  //       path: "user",
  //       component: () => import("@/views/distribution/user/index"),
  //       name: "UC用户管理",
  //       code: "user_management",
  //       meta: {
  //         title: "UC用户管理",
  //         onCache: true,
  //       },
  //     },
  //     {
  //       path: "userList",
  //       component: () => import("@/views/stats/userList.vue"),
  //       name: "用户列表",
  //       code: "user_list",
  //       meta: {
  //         title: "用户列表",
  //         icon: "documentation",
  //         noCache: true,
  //       },
  //     },
  //     {
  //       path: "userStats",
  //       component: () => import("@/views/stats/userStats.vue"),
  //       name: "用户数据统计",
  //       code: "user_stats",
  //       meta: {
  //         title: "用户数据统计",
  //         onCache: true,
  //       },
  //     },
  //     {
  //       path: "tranStats",
  //       component: () => import("@/views/stats/tranStats.vue"),
  //       name: "交易数据统计",
  //       code: "user_stats", // TODO
  //       meta: {
  //         title: "交易数据统计",
  //         onCache: true,
  //       },
  //     },
  //     {
  //       path: "openStats",
  //       component: () => import("@/views/stats/openStats.vue"),
  //       name: "开放平台数据统计",
  //       code: "open_stats",
  //       meta: {
  //         title: "开放平台数据统计",
  //         onCache: true,
  //       },
  //     },
  //     {
  //       path: "openapiStats",
  //       component: () => import("@/views/stats/openapiStats.vue"),
  //       name: "开放平台API统计",
  //       code: "openapi_stats",
  //       meta: {
  //         title: "开放平台API统计",
  //         icon: "documentation",
  //         noCache: true,
  //       },
  //     },
  //     {
  //       path: "promotionStats",
  //       component: () => import("@/views/stats/promotionStats.vue"),
  //       name: "活动数据统计",
  //       code: "activity_stats",
  //       meta: {
  //         title: "活动数据统计",
  //         onCache: true,
  //       },
  //     },
  //     {
  //       path: "errCodeList",
  //       component: () => import("@/views/stats/errCodeList.vue"),
  //       name: "错误码列表",
  //       code: "errcode",
  //       meta: {
  //         title: "错误码列表",
  //         icon: "documentation",
  //         noCache: true,
  //       },
  //     },
  //   ],
  // },
  //  TODO 隐藏部分
  // {
  //   path: "/datas",
  //   component: Layout,
  //   name: "数据统计查询",
  //   // code: "datas",
  //   meta: {
  //     title: "数据统计查询",
  //   },
  //   children: [
  //     {
  //       path: "asset",
  //       component: () => import("@/views/datas/asset"),
  //       name: "资产数据",
  //       // code: "datas_asset",
  //       meta: {
  //         title: "资产数据",
  //         onCache: true,
  //       },
  //     },
  //     {
  //       path: "user",
  //       component: () => import("@/views/datas/user"),
  //       name: "用户数据",
  //       // code: "datas_user",
  //       meta: {
  //         title: "用户数据",
  //         onCache: true,
  //       },
  //     },
  //   ],
  // },
  {
    path: "/user",
    component: Layout,
    name: "账户管理",
    code: "admin",
    meta: {
      title: "账户管理",
    },
    children: [
      {
        path: "accounts",
        component: () => import("@/views/user/account"),
        name: "账户列表",
        code: "admin_list",
        meta: {
          title: "账户列表",
          onCache: true,
        },
      },
      // {
      //   path: "password",
      //   component: () => import("@/views/user/password"),
      //   name: "我的密码",
      //   code: "password",
      //   meta: {
      //     title: "我的密码",
      //     onCache: true,
      //   },
      // },
    ],
  },
  {
    path: "/404",
    component: () => import("@/views/404"),
    hidden: true,
  },
  {
    path: "*",
    redirect: "/404",
    hidden: true,
  },
  ...nftRouters,
  {
    path: "/develop",
    component: Layout,
    name: "开放平台统计管理",
    code: "open_stats",
    meta: {
      title: "开放平台统计管理",
    },
    children: [
      {
        path: "orderlist",
        component: () => import("@/views/develop/orderList.vue"),
        name: "代收款订单流水",
        code: "collection_order",
        meta: {
          title: "代收款订单流水",
          onCache: true,
        },
      },
      {
        path: "statistical",
        component: () => import("@/views/develop/statistical.vue"),
        name: "代收款金额统计",
        code: "collection_amount",
        meta: {
          title: "代收款金额统计",
          onCache: true,
        },
      },
      {
        path: "paymentOrderRecords",
        component: () => import("@/views/develop/paymentOrderRecords.vue"),
        name: "应用支付流水",
        meta: {
          title: "应用支付流水",
          onCache: true,
        },
      },
      {
        path: "paymentOrderStat",
        component: () => import("@/views/develop/paymentOrderStat.vue"),
        name: "应用支付统计",
        meta: {
          title: "应用支付统计",
          onCache: true,
        },
      },
      {
        path: "doubleRisk",
        component: () => import("@/views/develop/doubleRisk.vue"),
        name: "双花风险交易列表",
        code: "double_spend_transaction",
        meta: {
          title: "双花风险交易列表",
          noCache: true,
        },
      },
    ],
  },
  {
    path: "/distribution",
    component: Layout,
    name: "开放平台分销",
    code: "open_distribution",
    meta: {
      title: "开放平台分销",
    },
    children: [
      {
        path: "case",
        component: () => import("@/views/distribution/case/index"),
        name: "分享赚计划",
        code: "distribution_plan",
        meta: {
          title: "分享赚计划",
          onCache: true,
        },
      },
      {
        path: "case/record",
        component: () => import("@/views/distribution/case/record.vue"),
        name: "奖励明细",
        code: "distribution_plan",
        meta: {
          title: "奖励明细",
          noCache: true,
          activeMenu: "/distribution",
        },
        hidden: true,
      },
      {
        path: "case/user",
        component: () => import("@/views/distribution/case/user.vue"),
        name: "客户明细",
        code: "distribution_plan",
        meta: {
          title: "客户明细",
          noCache: true,
          activeMenu: "/distribution",
        },
        hidden: true,
      },
      {
        path: "case/edit",
        component: () => import("@/views/distribution/case/edit.vue"),
        name: "编辑计划",
        code: "distribution_plan",
        meta: {
          title: "编辑计划",
          noCache: true,
          activeMenu: "/distribution",
        },
        hidden: true,
      },
      {
        path: "case/create",
        component: () => import("@/views/distribution/case/create.vue"),
        name: "添加计划",
        code: "distribution_plan",
        meta: {
          title: "添加计划",
          noCache: true,
          activeMenu: "/distribution",
        },
        hidden: true,
      },
      {
        path: "case/info",
        component: () => import("@/views/distribution/case/info.vue"),
        name: "查看计划",
        code: "distribution_plan",
        meta: {
          title: "查看计划",
          noCache: true,
          activeMenu: "/distribution",
        },
        hidden: true,
      },
    ],
  },
  {
    path: "/star",
    component: Layout,
    name: "星界管理后台",
    code: "social",
    meta: {
      title: "星界管理后台",
    },
    children: [
      {
        path: "case",
        component: () => import("@/views/star-admin/emojiManage.vue"),
        name: "表情包管理",
        code: "social_emoticon",
        meta: {
          title: "表情包管理",
          onCache: true,
        },
      },
      {
        path: "withdraw",
        component: () => import("@/views/star-admin/withdrawManage.vue"),
        name: "星界-提现管理",
        //  code: "nft_withdrawal_order",
        meta: {
          title: "星界-提现管理",
          // icon: "settings",
          noCache: true,
        },
      },
      {
        path: "vip",
        component: () => import("@/views/star-admin/vip/index.vue"),
        name: "加V管理",
        meta: {
          title: "加V管理",
          noCache: true,
        },
      },
    ],
  },
  {
    path: "/tiertop",
    component: Layout,
    name: "tiertop管理后台",
    code: "social",
    meta: {
      title: "tiertop管理后台",
    },
    children: [
      {
        path: "collection",
        component: () => import("@/views/tiertop/collectionManage.vue"),
        name: "tiertop专辑管理",
        // code: "social_emoticon",
        meta: {
          title: "tiertop专辑管理",
          onCache: true,
        },
      },
      // {
      //   path: "withdraw",
      //   component: () => import("@/views/star-admin/withdrawManage.vue"),
      //   name: "参数配置",
      //   //  code: "nft_withdrawal_order",
      //   meta: {
      //     title: "参数配置",
      //     // icon: "settings",
      //     noCache: true,
      //   },
      // },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: constantRouterMap,
});

export default router;
