import axios from "axios";
import config from "@/configs/index";

const OPEN_URL = config.openHost;

// 获取banner列表
export const fetchBannerList = (params) => {
  return axios({
    url: OPEN_URL + "/v1/bsv_admin/promotion20210610_get_banner_pic",
    method: "post",
    data: params,
  });
};

// 添加banner
export const postBanner = (params) => {
  return axios({
    url: OPEN_URL + "/v1/bsv_admin/promotion20210610_add_banner_pic",
    method: "post",
    data: params,
  });
};

// 修改banner
export const editBanner = (params) => {
  return axios({
    url: OPEN_URL + "/v1/bsv_admin/promotion20210610_update_banner_pic",
    method: "post",
    data: params,
  });
};
