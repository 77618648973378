import axios from "axios";
import config from "@/configs/index";

const PRE_URL = config.url + "/admin_wallet/api/admin";

// 修改密码
export const editPassWord = (data) => {
  return axios({
    url: PRE_URL + "/changePassword",
    method: "put",
    data,
  });
};

//管理员列表
export const getAdministrator = (query) => {
  return axios({
    url: PRE_URL + "/administrator",
    method: "get",
    params: query,
  });
};

// 新增管理员
export const addAdministrator = (data) => {
  return axios({
    url: PRE_URL + "/administrator",
    method: "post",
    data,
  });
};

// 更新管理员
export const editAdministrator = (params) => {
  // console.log(params)
  return axios({
    url: PRE_URL + "/administrator/" + params.id,
    method: "put",
    data: params,
  });
};

// 删除管理员
export const deleteAdmin = (id) => {
  return axios({
    url: PRE_URL + "/administrator/" + id,
    method: "delete",
  });
};

// 管理员重置密码
export const resetPassword = (params) => {
  return axios({
    url: PRE_URL + "/administrator/resetPassword/" + params.id,
    method: "put",
    data: params,
  });
};

// 管理员分组列表
export const getAdminGroup = (query) => {
  return axios({
    url: PRE_URL + "/group",
    method: "get",
    params: query,
  });
};

// 新增管理员分组
export const addAdministratorGroup = (data) => {
  return axios({
    url: PRE_URL + "/group",
    method: "post",
    data,
  });
};

// 更新管理员分组
export const editAdminGroup = (params) => {
  // console.log(params)
  return axios({
    url: PRE_URL + "/group/" + params.id,
    method: "put",
    data: params,
  });
};

// 删除管理员分组
export const deleteAdminGroup = (id) => {
  return axios({
    url: PRE_URL + "/group/" + id,
    method: "delete",
  });
};

export const getMenu = () => {
  return axios({
    url: PRE_URL + "/menu",
    method: "get",
  });
};

export const getUserInfo = () => {
  return axios({
    url: PRE_URL + "/info",
    method: "get",
  });
};

export const setUserPayPasswordEmpty = (data) => {
  return axios({
    url: PRE_URL + "/sysConfig/setUserPayPasswordEmpty",
    method: "post",
    data,
  });
};

// 上传nft图片
export async function uploadImageWithCompress(data) {
  return axios({
    url: `${config.walletApiHost}/api/uploadImageWithCompress`,
    method: "post",
    data,
  });
}
