import axios from "axios";
import config from "@/configs/index";

const PRE_URL = config.url + "/admin_wallet/api";

// 获取广告列表
export const fetchAdList = (query) => {
  return axios({
    url: PRE_URL + "/ad/list",
    method: "get",
    params: query,
  });
};

// 添加广告
export const postAd = (params) => {
  return axios({
    url: PRE_URL + "/ad",
    method: "post",
    data: params,
  });
};

// 修改广告
export const editAd = (params) => {
  return axios({
    url: PRE_URL + "/ad/" + params.id,
    method: "put",
    data: params,
  });
};

// 删除文章类别置顶
export const deleteAd = (id) => {
  return axios({
    url: PRE_URL + "/ad/" + id,
    method: "delete",
  });
};

// 文章类别置顶
export const shelfAd = (data) => {
  return axios({
    url: PRE_URL + "/ad/shelf",
    method: "post",
    data: data,
  });
};
