import {
  fetchNewsList,
  fetchCategoryList,
  deleteNews as deleteNewsById,
} from "@/api/news";
import { Message } from "element-ui";

const state = {
  newsList: {
    items: [],
    total: 0,
  },
  categoryList: {
    items: [],
    total: 0,
  },
};

const mutations = {
  SET_NEWSLIST: (state, data) => {
    state.newsList = {
      items: data.data_list,
      total: data.total_num,
    };
  },
  SET_CATEGORYLIST: (state, data) => {
    console.log(data);
    state.categoryList = {
      items: data.data_list,
      total: data.total_num,
    };
  },
  DELETE_NEWS: (state, id) => {
    state.newsList = {
      items: state.newsList.items.filter((news) => news.id !== id),
      total: state.newsList.total - 1,
    };
  },
};

const actions = {
  getNewsList({ commit }, query) {
    fetchNewsList(query).then((data) => {
      console.log(data);
      commit("SET_NEWSLIST", data.data.data);
    });
  },
  getCategoryList({ commit }, query) {
    fetchCategoryList(query).then((data) => {
      console.log(data);
      commit("SET_CATEGORYLIST", data.data.data);
    });
  },
  deleteNews({ commit }, id) {
    deleteNewsById(id).then(() => {
      commit("DELETE_NEWS", id);
      Message.success({
        message: "删除成功",
        duration: 2000,
      });
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
