<template>
  <div>
    <el-container>
      <el-aside width="200px">
        <div class="logo-container">
          <a href="/" class="logo-link">
            <img src="@/assets/logo-boquan.png" width="32" />
            <h1>博泉管理后台</h1>
          </a>
        </div>
        <el-menu
          :default-active="activeIndex"
          background-color="#304156"
          text-color="#fff"
          active-text-color="#ffd04b"
          router
        >
          <el-submenu
            v-for="(route, index) in filterHidden(routes)"
            :index="`${route.path}`"
            :key="`submenu-${index}`"
          >
            <template slot="title">{{ route.name }}</template>
            <el-menu-item
              v-for="(item, subIndex) in filterHidden(route.children)"
              :index="`${route.path}/${item.path}`"
              :key="`menu-item-${subIndex}`"
              :route="item"
            >
              {{ item.name }}
            </el-menu-item>
          </el-submenu>
        </el-menu>
      </el-aside>
      <el-container>
        <el-header height>
          <!-- Header content -->
          <breadcrumb id="breadcrumb-container" class="breadcrumb-container" />
          <el-button type="info" class="logout-button" @click="logout">
            退出
          </el-button>
        </el-header>
        <el-main height>
          <app-main />
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import router from "../router";
import { mapGetters } from "vuex";
import AppMain from "./components/AppMain.vue";
import Breadcrumb from "@/components/Breadcrumb";

export default {
  computed: {
    ...mapGetters(["routes"]),
  },
  data() {
    console.log(router.currentRoute);
    const matchedList = router.currentRoute.matched;

    return {
      activeIndex: matchedList[matchedList.length - 1].path,
    };
  },
  methods: {
    // onSelect(val) {
    //   console.log(val);
    // },
    filterHidden(routes) {
      if (!routes) {
        return [];
      }
      return routes.filter((route) => !route.hidden);
    },
    logout() {
      localStorage.removeItem("token");
      this.$store.dispatch("route/clearRoles");
      router.replace({
        path: "/login",
        query: {
          //redirect: router.currentRoute.fullPath
        },
      });
    },
  },
  components: {
    AppMain,
    Breadcrumb,
  },
};
</script>

<style lang="less" scoped>
.logo-container {
  height: 50px;
  background: #2b2f3a;

  .logo-link {
    display: flex;
    width: 100%;
    overflow: hidden;
    height: 50px;
    text-decoration: none;
    align-items: center;
    justify-content: center;

    img {
      vertical-align: middle;
      margin-right: 12px;
      min-width: 32px;
      height: 32px;
    }

    h1 {
      font-size: 14px;
      color: #fff;
    }
  }
}
.el-container {
  height: 100vh;
  min-height: 100%;
  max-height: 100vh;

  .el-main {
    padding: 0;
  }

  .el-menu {
    border-right: none;

    &.el-menu--inline {
      > .el-menu-item {
        background-color: #1f2d3d !important;

        &:hover {
          background-color: #001528 !important;
        }
      }
    }
  }

  .el-header {
    text-align: left;
    height: 50px;
    box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
  }
}
.el-aside {
  background-color: #304156;
}
.logout-button {
  position: absolute;
  top: 5px;
  right: 50px;
}
</style>
