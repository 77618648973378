import Layout from "@/layout/index.vue";

export const nftRouters = [
  {
    path: "/nft_content",
    component: Layout,
    code: "nft_content",
    name: "内容管理",
    meta: {
      title: "内容管理",
    },
    children: [
      {
        path: "banner/:id",
        component: () => import("@/views/nft/content/banner/index.vue"),
        name: "轮播图管理",
        code: "nft_banner",
        meta: {
          title: "轮播图管理",
          noCache: true,
          activeMenu: "/nft_content",
        },
        hidden: true,
      },
      {
        path: "position",
        component: () => import("@/views/nft/content/banner/position.vue"),
        name: "轮播位管理",
        code: "nft_banner",
        meta: {
          title: "轮播位管理",
          noCache: true,
        },
      },
      {
        path: "category",
        component: () => import("@/views/nft/content/category/index.vue"),
        name: "分类管理",
        code: "nft_category",
        meta: {
          title: "分类管理",
          noCache: true,
        },
      },
      {
        path: "subject",
        component: () => import("@/views/nft/content/subject/index.vue"),
        name: "专题管理",
        code: "nft_subject",
        meta: {
          title: "专题管理",
          noCache: true,
        },
      },
      {
        path: "help-category",
        component: () => import("@/views/nft/content/help/category/index.vue"),
        name: "帮助栏目",
        code: "nft_theme",
        meta: {
          title: "帮助栏目",
          noCache: true,
        },
      },
      {
        path: "help-content",
        component: () => import("@/views/nft/content/help/index.vue"),
        name: "帮助内容",
        code: "nft_theme",
        meta: {
          title: "帮助内容",
          noCache: true,
        },
      },
    ],
  },
  {
    path: "/nft_users",
    component: Layout,
    code: "nft_user_management",
    name: "恒境-用户管理",
    meta: {
      title: "恒境-用户管理",
    },
    children: [
      {
        path: "",
        component: () => import("@/views/nft/users/index.vue"),
        name: "用户管理",
        code: "nft_userlist",
        meta: {
          title: "用户管理",
          noCache: true,
        },
      },
      {
        path: "artist",
        component: () => import("@/views/nft/users/artist/index.vue"),
        name: "艺术家管理",
        // code: "nft_userlist",
        meta: {
          title: "艺术家管理",
          noCache: true,
        },
      },
      {
        path: "achievement",
        component: () => import("@/views/nft/users/achievement/index.vue"),
        name: "成就审核",
        code: "nft_identification",
        meta: {
          title: "成就审核",
          noCache: true,
        },
      },
      {
        path: "account-opening",
        component: () => import("@/views/nft/users/account-opening/index.vue"),
        name: "用户开户",
        // code: "account_opening",
        meta: {
          title: "用户开户",
          noCache: true,
        },
      },
      {
        path: "artist-bonus",
        component: () => import("@/views/nft/users/artist-bonus/index.vue"),
        name: "艺术家分红记录",
        // code: "account_opening",
        meta: {
          title: "艺术家分红记录",
          noCache: true,
        },
      },
    ],
  },
  {
    path: "/nft_management",
    component: Layout,
    code: "nft_management",
    name: "恒境-NFT管理",
    meta: {
      title: "恒境-NFT管理",
    },
    children: [
      {
        path: "album",
        component: () => import("@/views/nft/manage/album/index.vue"),
        name: "专辑管理",
        // code: "nft_management_sub",
        meta: {
          title: "专辑管理",
          noCache: true,
        },
      },
      {
        path: "",
        component: () => import("@/views/nft/manage/index.vue"),
        name: "NFT合集管理",
        code: "nft_management_sub",
        meta: {
          title: "NFT合集管理",
          noCache: true,
        },
      },
      {
        path: "nft_items",
        component: () => import("@/views/nft/manage/items.vue"),
        name: "NFT管理",
        code: "nft_management_sub",
        meta: {
          title: "NFT管理",
          noCache: true,
        },
      },
      {
        path: "nft_attribute",
        component: () => import("@/views/nft/manage/attribute/index.vue"),
        name: "作品属性管理",
        meta: {
          title: "作品属性管理",
          noCache: true,
        },
      },
      {
        path: "transfer",
        component: () => import("@/views/nft/manage/transfer/index.vue"),
        name: "转让订单",
        code: "nft_transfer_order",
        meta: {
          title: "转让订单",
          noCache: true,
        },
      },
      {
        path: "trade",
        component: () => import("@/views/nft/manage/trade/index.vue"),
        name: "交易订单",
        code: "nft_trade_order",
        meta: {
          title: "交易订单",
          noCache: true,
        },
      },
      {
        path: "audit",
        component: () => import("@/views/nft/manage/audit/index.vue"),
        name: "上架审核",
        code: "nft_shelf_audit",
        meta: {
          title: "上架审核",
          noCache: true,
        },
      },
      {
        path: "batch",
        component: () => import("@/views/nft/manage/batch/index.vue"),
        name: "批次管理",
        // code: "nft_shelf_audit",
        meta: {
          title: "批次管理",
          noCache: true,
        },
      },
      {
        path: "post-new",
        component: () => import("@/views/nft/events-manage/priority-buy/index.vue"),
        name: "上新管理",
        // code: "nft_management_sub",
        meta: {
          title: "上新管理",
          noCache: true,
        },
      },
      {
        path: "compose-manage",
        component: () => import("@/views/nft/manage/compose/index.vue"),
        name: "合成管理",
        // code: "nft_shelf_audit",
        meta: {
          title: "合成管理",
          noCache: true,
        },
      },
      {
        path: "serise-manage",
        component: () => import("@/views/nft/manage/series/index.vue"),
        name: "系列管理",
        // code: "nft_shelf_audit",
        meta: {
          title: "系列管理",
          noCache: true,
        },
      },
      {
        path: "shop-audit-manage",
        component: () => import("@/views/nft/manage/shop-audit/index.vue"),
        name: "小店作品审核",
        // code: "nft_shelf_audit",
        meta: {
          title: "小店作品审核",
          noCache: true,
        },
      },
      {
        path: "build-up-manage",
        component: () => import("@/views/nft/manage/build-up/index.vue"),
        name: "合成品管理",
        // code: "nft_shelf_audit",
        meta: {
          title: "合成品管理",
          noCache: true,
        },
      },
    ],
  },
  {
    path: "/nft_events_management",
    component: Layout,
    // code: "nft_events_management",
    name: "恒境-活动管理",
    meta: {
      title: "恒境-活动管理",
    },
    children: [
      {
        path: "zodiac_snapshot",
        component: () => import("@/views/nft/events-manage/snapshot/index.vue"),
        name: "十二生肖-快照",
        // code: "nft_management_sub",
        meta: {
          title: "十二生肖-快照",
          noCache: true,
        },
      },
      {
        path: "copyright-license",
        component: () => import("@/views/nft/events-manage/copyright-license/index.vue"),
        name: "著作权许可费管理",
        // code: "nft_management_sub",
        meta: {
          title: "著作权许可费管理",
          noCache: true,
        },
      },
      {
        path: "fly",
        component: () => import("@/views/nft/events-manage/fly/index.vue"),
        name: "大拉新活动",
        // code: "nft_management_sub",
        meta: {
          title: "大拉新活动",
          noCache: true,
        },
      },
      {
        path: "fly-vote",
        component: () => import("@/views/nft/events-manage/fly/vote-manage/index.vue"),
        name: "活动投票管理",
        // code: "nft_management_sub",
        meta: {
          title: "活动投票管理",
          noCache: true,
        },
      },
      {
        path: "artist-activity",
        component: () => import("@/views/nft/events-manage/artist-activity/index.vue"),
        name: "艺术家活动列表",
        meta: {
          title: "艺术家活动列表",
          noCache: true,
        },
      },
      {
        path: "crowd-fund",
        component: () => import("@/views/nft/events-manage/crowd-fund/index.vue"),
        name: "众筹项目管理",
        meta: {
          title: "众筹项目管理",
          noCache: true,
        },
      },
      {
        path: "lucky-draw",
        component: () => import("@/views/nft/events-manage/lucky-draw/index.vue"),
        name: "积分抽奖",
        // code: "nft_managementlucky-draw",
        meta: {
          title: "积分抽奖",
          noCache: true,
        },
      },
      //
    ],
  },
  {
    path: "/nft_management_log",
    component: Layout,
    name: "恒境-统计分析",
    code: "nft_management_log",
    meta: {
      title: "恒境-统计分析",
    },
    children: [
      {
        path: "data",
        component: () => import("@/views/nft/manage-log/index.vue"),
        name: "经营数据日志",
        code: "nft_management_data",
        meta: {
          title: "经营数据日志",
          noCache: true,
        },
      },
      {
        path: "asset",
        component: () => import("@/views/nft/asset-manage/index.vue"),
        name: "用户资产管理",
        code: "nft_user_asset", //todo
        meta: {
          title: "用户资产管理",
          noCache: true,
        },
      },
      {
        path: "wallet",
        component: () => import("@/views/nft/wallet-manage/index.vue"),
        name: "用户资产归属",
        code: "nft_user_asset", //todo
        meta: {
          title: "用户资产归属",
          noCache: true,
        },
      },
      {
        path: "sell-works",
        component: () => import("@/views/nft/statistics/sell-works/index.vue"),
        name: "在售作品统计",
        // code: "nft_management_data",
        meta: {
          title: "在售作品统计",
          noCache: true,
        },
      },
      {
        path: "artist-manage",
        component: () => import("@/views/nft/artist-manage/index.vue"),
        name: "艺术家统计",
        // code: "nft_user_asset", //todo
        meta: {
          title: "艺术家统计",
          noCache: true,
        },
      },
      {
        path: "artist-lucky-bags",
        component: () => import("@/views/nft/statistics/lucky-bags/index.vue"),
        name: "艺术家福袋统计",
        meta: {
          title: "艺术家福袋统计",
          noCache: true,
        },
      },
    ],
  },
  {
    path: "/nft_config",
    component: Layout,
    name: "恒境-参数管理",
    code: "nft_system_config",
    meta: {
      title: "恒境-参数管理",
      // icon: "international",
    },
    children: [
      {
        path: "system",
        component: () => import("@/views/nft/system-config/index.vue"),
        name: "系统参数",
        code: "nft_config_data",
        meta: {
          title: "系统参数",
          // icon: "settings",
          noCache: true,
        },
      },
      {
        path: "cache-list",
        component: () => import("@/views/nft/system-config/cache-list/index.vue"),
        name: "缓存列表",
        //  code: "nft_config_data",
        meta: {
          title: "缓存列表",
          // icon: "settings",
          noCache: true,
        },
      },
      {
        path: "auth-scope",
        component: () => import("@/views/nft/system-config/auth-scope/index.vue"),
        name: "授权范围设置",
        code: "nft_config_data",
        meta: {
          title: "授权范围设置",
          // icon: "settings",
          noCache: true,
        },
      },
    ],
  },
  {
    path: "/nft_finance",
    component: Layout,
    name: "恒境-财务统计",
    code: "nft_finance_stats",
    meta: {
      title: "恒境-财务统计",
    },
    children: [
      {
        path: "report",
        component: () => import("@/views/nft/finance/report/index.vue"),
        name: "财务统计报表",
        code: "nft_finance_report",
        meta: {
          title: "财务统计报表",
          noCache: true,
        },
      },
      {
        path: "balance-snapshot",
        component: () => import("@/views/nft/finance/balance-snapshot/index.vue"),
        name: "渠道余额快照",
        code: "nft_channel_balance",
        meta: {
          title: "渠道余额快照",
          noCache: true,
        },
      },
      {
        path: "transfer-detail",
        component: () => import("@/views/nft/finance/transaction-detail/index.vue"),
        name: "交易详情",
        code: "nft_orders",
        meta: {
          title: "交易详情",
          noCache: true,
        },
      },
    ],
  },
  {
    path: "/nft_orders",
    component: Layout,
    name: "恒境-订单管理",
    code: "nft_order_management",
    meta: {
      title: "恒境-订单管理",
      // icon: "international",
    },
    children: [
      {
        path: "onshelf",
        component: () => import("@/views/nft/orders/onshelf-orders/index.vue"),
        name: "上架订单",
        code: "nft_shelf_order",
        meta: {
          title: "上架订单",
          // icon: "settings",
          noCache: true,
        },
      },
      {
        path: "withdraw",
        component: () => import("@/views/nft/orders/withdraw-orders/index.vue"),
        name: "提现管理",
        code: "nft_withdrawal_order",
        meta: {
          title: "提现管理",
          // icon: "settings",
          noCache: true,
        },
      },
      {
        path: "refund",
        component: () => import("@/views/nft/orders/refund-orders/index.vue"),
        name: "退款订单查询",
        code: "nft_refund_order",
        meta: {
          title: "退款订单查询",
          noCache: true,
        },
      },
      {
        path: "verification",
        component: () => import("@/views/nft/orders/verification-orders/index.vue"),
        name: "核销记录",
        // code: "nft_refund_order",
        meta: {
          title: "核销记录",
          noCache: true,
        },
      },
      {
        path: "lucky-bags",
        component: () => import("@/views/nft/orders/lucky-bags/index.vue"),
        name: "福袋销售记录",
        meta: {
          title: "福袋销售记录",
          noCache: true,
        },
      },
    ],
  },
  {
    path: "/nft_base",
    component: Layout,
    name: "恒境-基本管理",
    code: "nft_base",
    meta: {
      title: "恒境-基本管理",
      // icon: "international",
    },
    children: [
      {
        path: "finance",
        component: () => import("@/views/nft/base-manage/finance/index.vue"),
        name: "资金流水",
        code: "nft_finance",
        meta: {
          title: "资金流水",
          // icon: "settings",
          noCache: true,
        },
      },
      {
        path: "version",
        component: () => import("@/views/nft/base-manage/version/index.vue"),
        name: "版本管理",
        code: "nft_version",
        meta: {
          title: "版本管理",
          // icon: "settings",
          noCache: true,
        },
      },
    ],
  },
];
