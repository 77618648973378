import axios from "axios";
import config from "@/configs/index";

const Host = config.url + "/admin_wallet/api/admin";

// 用户数据统计
export const fetchUserStats = (query) => {
  return axios({
    url: Host + "/userStats",
    method: "get",
    params: query,
  });
};

// 用户数据统计（from UCenter)
export const fetchStatisticsFromUc = (query) => {
  return axios({
    url: config.openHost + "/v1/user/statistics",
    method: "post",
    data: query,
    timeout: 0,
  });
};

// 查询助记词备份用户数
export const getMnemonicBackedUserCount = async (conditions) => {
  try {
    const res = await axios({
      url: config.openHost + "/v1/phoenix_admin/get_mnemonic_backed_user_count",
      method: "post",
      data: { conditions },
      timeout: 0,
    });
    if (+res.data.code === 0) {
      return res.data.data.cnts.map((cnt) => cnt.cnt);
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
};
// 查询拥有余额用户数
export const getHaveBalanceUserCount = async (conditions) => {
  try {
    const res = await axios({
      url: config.openHost + "/v1/phoenix_admin/get_user_count_have_balance",
      method: "post",
      data: { conditions },
      timeout: 0,
    });
    if (+res.data.code === 0) {
      return res.data.data.cnts.map((cnt) => cnt.cnt);
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
};

// 查询助记词备份用户数
export const getTransferUserCount = async (conditions) => {
  try {
    const res = await axios({
      url: config.openHost + "/v1/phoenix_admin/get_transfer_user_count",
      method: "post",
      data: { conditions },
      timeout: 0,
    });
    if (+res.data.code === 0) {
      return res.data.data.cnts.map((cnt) => cnt.cnt);
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
};

// 用户列表
export const fetchUserList = (query) => {
  return axios({
    url: Host + "/getUserList",
    method: "get",
    params: query,
  });
};

// 交易数据统计
export const fetchTranStats = (query) => {
  return axios({
    url: Host + "/tranStats",
    method: "get",
    params: query,
  });
};

// 开放平台数据统计
export const fetchOpenStats = (query) => {
  return axios({
    url: Host + "/openStats",
    method: "get",
    params: query,
  });
};

// 开放平台api统计
export const fetchOpenapiStats = (query) => {
  return axios({
    url: Host + "/getOpenapiStats",
    method: "get",
    params: query,
  });
};

// 活动数据统计
export const fetchPromotionStats = (query) => {
  return axios({
    url: Host + "/promotionStats",
    method: "get",
    params: query,
  });
};

// 错误码列表
export const fetchErrCodeList = (query) => {
  return axios({
    url: Host + "/getErrCodeList",
    method: "get",
    params: query,
  });
};

// 编辑错误码
export const editErrCode = (data) => {
  return axios({
    url: Host + "/editErrCode",
    method: "post",
    data,
  });
};

export const fetchAssets = (data) => {
  return axios({
    url: config.openHost + "/v1/phoenix_admin/get_asset_data_amount",
    method: "post",
    data,
    timeout: 0,
  });
};

export const fetchRate = () => {
  return axios({
    url: "https://www.ddpurse.com/api/v1/rate",
    method: "get",
  });
};
